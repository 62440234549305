import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Login } from "./Pages/Login";
import { Register } from "./Pages/register";
import { Home } from "./Pages/home";
import { useState } from "react";

export const AppRoutes = () => {
  const [user, setUser] = useState(null);
  const login = (email: string, password: string) => {};
  const logout = () => {};

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  );
};
